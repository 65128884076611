import {OPL_ACTION_FOR_DELTA_CALCULATIONS, ORDER_EVENT_TYPES_FOR_DELTA_CALCULATIONS} from "../config/constants";
import {testOrder} from "../test/order";


function mapEventToCreationDateTime() {
    return (event) => event.creationDate;
}

function mapEventToSuccess() {
    return (event) => event.success ? "Skuteczna" : "Nieskuteczna";
}

export function mapToFinishedWorkSuccess(success) {
    return success ? "Skuteczna" : "Nieskuteczna";
}

const EVENT_MAP = {

    "STATUS_CHANGE": "Zmiana statusu",
    "OPL_ASSIGNED_TO_ORDER": "Przypisanie się do zlecenia przez użytkownika OPL",
    "COMPLETED_BY_INSTALLER": "Uzupełnienie zlecenia przez serwisanta",
    "WORK_FINISHED_BY_INSTALLER": "Zakończenie pracy przez serwisanta",
    "ORDER_REJECTED_EVENT": "Cofnięcie zlecenia",
    "INSTALLER_REMOVED_FROM_ORDER_EVENT": "Usunięcie serwisanta ze zlecenia",
    "INSTALLER_ADDED_TO_ORDER_EVENT": "Serwisant dodany do zlecenia",
    "TIMESLOT_CHANGE_EVENT": "Zmiana daty umówienia",
    "OPL_UNASSIGNED_TO_ORDER": "Wypisanie się ze zlecenia użytkownika OPL",
    "OPL_NOTE_ADDED_EVENT": "Dodana notatka OPL",
    "AUTOMATIC_ARCHIVING_EVENT": "Zlecenie zarchiwizowane automatycznie",
    "AUTOMATIC_OPL_UNASSIGN_FROM_ORDER_EVENT": "Automatyczne odpisanie użytkownika OPL od zlecenia.",
    "AUTOMATIC_BOT_UNASSIGN_FROM_ORDER_EVENT": "Automatyczne odpisanie robota od zlecenia.",
    "OPL_STATUS_CHANGE_EVENT": "Zmiana statusu DS (monitoring).",
    "PT_LATE": "Spóźnienie",
    "ORDER_ONTPP": "Zlecenie obsłużone przez ONTP&P",
    "ATRIUM_OPERATION_EVENT": "Przesłanie informacji do systemu ATRIUM",
    "NOT_ASSIGNED_ACCOUNT_ADDED_TO_CHAT_EVENT": "Otwarcie czatu bez przypisania do zlecenia",
    "ATLAS_EVENT": "Publikacja w ATLAS",
    "CHAT_OPENED_BY_OPL_USER": "Otwarcie czatu",
};
function compareByIds(a, b) {
    return a.id - b.id;
}
/**
 * It find's last event of searcher type.
 * @param events
 * @param type
 * @param map
 * @param filter
 * @returns {*}
 */
function findEventByTypeAndAndMap(events, type, map, filter) {
    if (events && Array.isArray(events) && events.length > 0) {
        let array = events.filter((event) => type === event.type);
        if (filter && array.length > 0) {
            array = array.filter(filter);
        }
        if (array.length > 0) {
            const sorted = array.sort(compareByIds);
            if (map) {
                array = sorted.map(map());
            }
            return array[array.length - 1];
        }
    }
    return null;
}



const OrderEventUtil = {

    test(){
        this.getDeltas(testOrder);
    },

    getDeltas(order) {

        function findLastIndexOfEventByTypeWithFirstIndex(events, firstIndex, type) {
            for (let i = events.length - 1; i >= firstIndex; i -= 1) {
                if (events[i].type === type) {

                    return i;
                }
            }
        }

        function findLastIndexOfEventByEventTypesAndStatuses(events, firstIndex, types, statuses) {
            for (let i = events.length - 1; i >= firstIndex; i -= 1) {
                const result = types.filter((type) => events[i].type === type);
                if (result && result.length > 0) {

                    for (let j = events.length - 1; j >= i; j -= 1) {
                        const result = statuses.filter((status) => events[j].status && events[j].status.name === status);
                        if (result && result.length > 0) {
                            return j;
                        }
                    }
                }
            }
        }

        function findLastIndexOfEventByType(events, type) {
            return findLastIndexOfEventByTypeWithFirstIndex(events, 0, type);
        }

        const deltas={
            fromCompletedOrderToOPLassignment:null,
            fromUserAssignmentToOPLAction:null,
            fromCompletedOrderToOPLAction:null
        };

        if (order && order.events && order.events.length > 0) {
            const events =order.events.sort(function(a,b){
                return new Date(a.creationDate) -new Date(b.creationDate); //rosnąco
            });
            // console.log("Dates = "+JSON.stringify(events,null,2))
            const completedByInstallerLastIndex = findLastIndexOfEventByType(events, 'COMPLETED_BY_INSTALLER');
            if (completedByInstallerLastIndex) {
                const completedByInstallerEvent = events[completedByInstallerLastIndex];

                const assignIndex = findLastIndexOfEventByTypeWithFirstIndex(events, completedByInstallerLastIndex, 'OPL_ASSIGNED_TO_ORDER');
                if (assignIndex) {

                    const assignEvent = events[assignIndex];
                    // console.log("assignIndex " + assignIndex);
                    // console.log("Assign id " + assignEvent['id']);
                    // console.log("completedByInstallerLastIndex " + completedByInstallerLastIndex);


                    const completedDateTime = new Date(completedByInstallerEvent['creationDate']);
                    const assignDateTime = new Date(assignEvent['creationDate']);
                    const differenceInMinuts = (assignDateTime-completedDateTime)/1000/60;

                    // console.log("completedDateTime " + completedDateTime);
                    // console.log("assignDateTime " + assignDateTime);
                    //
                    // console.log("assignment delta int minutes= " + Math.floor(differenceInMinuts));
                    deltas.fromCompletedOrderToOPLassignment =Math.floor(differenceInMinuts);

                    const lastOplActionIndex = findLastIndexOfEventByEventTypesAndStatuses(events, assignIndex,
                        ORDER_EVENT_TYPES_FOR_DELTA_CALCULATIONS, OPL_ACTION_FOR_DELTA_CALCULATIONS);
                    if (lastOplActionIndex) {
                        const lastOplAction = events[lastOplActionIndex];
                        const lastOplActionDateTime = new Date(lastOplAction['creationDate']);
                        const differenceInMinuts2 = (lastOplActionDateTime-assignDateTime)/1000/60;
                        deltas.fromUserAssignmentToOPLAction=Math.floor(differenceInMinuts2);
                        // console.log("OPL action delta int minutes= " + Math.floor(differenceInMinuts2));

                        const differenceInMinutes3 = (lastOplActionDateTime-completedDateTime)/1000/60;
                        deltas.fromCompletedOrderToOPLAction=Math.floor(differenceInMinutes3);
                    }

                }


            }
        }
        // console.log("Deltas = " + JSON.stringify(deltas,null,2));
        return deltas;
    },



    getCreationDateTimeFromWorkFinishedByInstaller(events) {
        return findEventByTypeAndAndMap(events, "WORK_FINISHED_BY_INSTALLER", mapEventToCreationDateTime);
    },

    getCreationDateTimeFromInstallerAddedToOrder(events, installerId) {
        return findEventByTypeAndAndMap(events, "INSTALLER_ADDED_TO_ORDER_EVENT", mapEventToCreationDateTime,
            (event) => {
                return event.installer.id === installerId
            });
    },


    getSuccessFromWorkFinishedByInstaller(events) {
        return findEventByTypeAndAndMap(events, "WORK_FINISHED_BY_INSTALLER", mapEventToSuccess);
    },


    getDateTimeFromEnroute(events) {
        return findEventByTypeAndAndMap(events, "STATUS_CHANGE", mapEventToCreationDateTime,
            (event) => {
                return event.status.name === "PT_ENROUTE";
            });
    },

    getDateTimeFromResignation(events) {
        return findEventByTypeAndAndMap(events, "STATUS_CHANGE", mapEventToCreationDateTime,
            (event) => {
                return event.status.name === "PT_RESIGNATION";
            });
    },

    getDateTimeFromClientPostpone(events) {
        return findEventByTypeAndAndMap(events, "STATUS_CHANGE", mapEventToCreationDateTime,
            (event) => {
                return event.status.name === "PT_CLIE_POSTPON";
            });
    },
    getDateTimeFromClientNoResponse(events) {
        return findEventByTypeAndAndMap(events, "STATUS_CHANGE", mapEventToCreationDateTime,
            (event) => {
                return event.status.name === "PT_NO_RESPONSE";
            });
    },
    getDateTimeFromWaitingForClient(events) {
        return findEventByTypeAndAndMap(events, "STATUS_CHANGE", mapEventToCreationDateTime,
            (event) => {
                return event.status.name === "PT_WAITING_FOR_CLI";
            });
    },
    getDateTimeFromQuestionFromContract(events) {
        return findEventByTypeAndAndMap(events, "STATUS_CHANGE", mapEventToCreationDateTime,
            (event) => {
                return event.status.name === "PT_QUESTIONS_FOR_THE_CONTRACT";
            });
    },

    getDateTimeFromTechPostpone(events) {
        return findEventByTypeAndAndMap(events, "STATUS_CHANGE", mapEventToCreationDateTime,
            (event) => {
                return event.status.name === "PT_TECH_POSTPONED";
            });
    },

    getDateTimeFromHandover(events) {
        return findEventByTypeAndAndMap(events, "STATUS_CHANGE", mapEventToCreationDateTime,
            (event) => {
                return event.status.name === "PT_HANDOVER";
            });
    },


    getVerboseNameOfOrderType(key) {
        return EVENT_MAP[key] ? EVENT_MAP[key] : key;
    }


};

export default OrderEventUtil;

