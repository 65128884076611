import axios from "axios";
import RestService from "./RestService";

const PATH = '/orderPreview/order';

const OrderPreviewService = {

    getMainQueueOrders(params, pageNumber, pageSize, onSuccess, onError) {
        if (!params) {
            params = {};
        }
        params["page"] = pageNumber;
        params["size"] = pageSize;
        return axios.get(PATH, {
            params: params
        })
            .then(function (response) {
                if (response.status === 200) {
                    onSuccess(response.data);
                } else {
                    const error = `Incorrect response status ${response.status}`;
                    onError(error);
                }

            })
            .catch(function (error) {
                onError(error);
            });
    },

    getById(id, onSuccess, onError) {
        return axios.get(PATH + "/" + id)
            .then(function (response) {
                if (response.status===200){
                    onSuccess(response.data);
                } else {
                    const error = `Incorrect response status ${response.status}`;
                    onError(error);
                }

            })
            .catch(function (error) {
                onError(error);
            });
    },

    getEventsByIdWithTimestamp(id, modificationDateTime, onSuccess, onError) {
        let params = { 'timestamp': modificationDateTime };
        return RestService.getWithParams(PATH + "/" + id + "/events", params, onSuccess, onError);
    },

    getChatHistoryById(id, onSuccess, onError) {
        return RestService.get(PATH + "/" + id + "/chatHistory", onSuccess, onError);
    },

};

export default OrderPreviewService;

