
//https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript


import {
    ATRIUM_CONNECT_EXCEPTION, ATRIUM_EVENT_INCORRECT_STATUS, ATRIUM_INCORRECT_HTTP_STATUS,
    ATRIUM_INTERNAL_ERROR, ATRIUM_NOT_FOUND_EXCEPTION,
    ATRIUM_SOCKET_TIMEOUT_EXCEPTION,
    BE_CONNECT_TIMEOUT,
    BE_READ_TIMEOUT,
    defaultDetail,
    defaultSummary, DP_BE_INTERNAL_ERROR,
    OPL_ALREADY_ASSIGNED_TO_ORDER_ERROR_CODE
} from "../config/constants";
import _ from "lodash";

function logResponse(resp) {
    console.log(`Response status: ${resp.status}`);
    const body = resp.data;
    if (body) {
        console.log(`Response body: ${JSON.stringify(body, null, 2)}`);
    }
}

function logRequest(obj) {
    if (obj.request) {
        console.log(`Request url: ${obj.request.url}`);
    }
}

const ErrorService = {

    mapResponseBodyToErrorTexts(body, defaultMsg) {
        let errorCode = _.get(body, "errorCode", null);

        if (errorCode) {
            switch (errorCode) {
                case 'OCL_CONNECT_TIMEOUT':
                    return { 'title': 'Błąd ', "msg": "Nie można się połączyć z serwerem OCL/Zulip." };
                case 'OCL_READ_TIMEOUT':
                    return { 'title': 'Błąd ', "msg": "Brak odpowiedzi od serwera OCL/Zulip(read timeout)." };
                case 'BE_CONNECT_TIMEOUT':
                    return { 'title': 'Błąd ', "msg": "Nie można się połączyć z serwerem BE." };
                case 'BE_READ_TIMEOUT':
                    return { 'title': 'Błąd ', "msg": "Brak odpowiedzi od serwera BE(read timeout)." };
                case 'OCL_OPEN_CHAT_RESULT_ERROR':
                    return { 'title': 'Błąd ', "msg": "Nie można otworzyć chatu. System OCL zwrócił błąd." };
                case 'OCL_ADD_USERS_TO_CHAT_RESULT_ERROR':
                    return { 'title': 'Błąd ', "msg": "Nie można dodać użytkownika do chatu. System OCL zwrócił błąd." };
                default:
                    return { 'title': 'Błąd ', "msg": "Fotomontaż napotkał błąd przy otwieraniu czatu" };
            }
        }
        return defaultMsg;
    },

    logServerError(obj) {

        const isErrorObject = obj && obj.response;
        if (isErrorObject) {
            const resp = obj.response;
            logResponse(resp);
            logRequest(obj);
        } else if (obj && obj.status) {
            logResponse(obj);
            logRequest(obj);
        }
    },

    getResponseBody(obj) {
        const isErrorObject = obj && obj.response;
        if (isErrorObject) {
            const resp = obj.response;
            return resp.data;
        } else if (obj && obj.status) {
            return obj.data;
        }
    },

    getErrorMsgToShow(obj, respFunc) {

        ErrorService.logServerError(obj);

        const body = ErrorService.getResponseBody(obj);
        let summary = defaultSummary;
        let detail = defaultDetail;
        if (body) {
            detail = this.getAtriumErrorMessage(obj);
        }
        respFunc(summary, detail);
    },

    getAtriumErrorMessage(response, defaultMessage) {
        let errorCode = _.get(response, "response.data.errorCode", null);
        switch (errorCode) {
            case OPL_ALREADY_ASSIGNED_TO_ORDER_ERROR_CODE:
                return "Inny użytkownik przypisał się do zlecenia!";
            case ATRIUM_EVENT_INCORRECT_STATUS:
                let atriumEventStatusCode = _.get(response, "response.data.atriumEventStatusCode", null);
                return `Niepoprawny status w odpowiedzi z Atrium. Kod błędu: ${atriumEventStatusCode}`;
            case ATRIUM_CONNECT_EXCEPTION:
                return "Nie można nawiązać połączenia z systemem Atrium!";
            case ATRIUM_SOCKET_TIMEOUT_EXCEPTION:
                return "Brak odpowiedzi od systemu Atrium.";
            case ATRIUM_NOT_FOUND_EXCEPTION:
                return "Serwery Atrium lub proxy fotomontażu (BE) są niedostępne.";
            case ATRIUM_INCORRECT_HTTP_STATUS:
                return "Niepoprawny status HTTP w odpowiedzi z Atrium!";
            case BE_CONNECT_TIMEOUT:
                return "Nie można się połączyć z serwerem proxy fotomontażu (BE).";
            case BE_READ_TIMEOUT:
                return "Brak odpowiedzi od serwera proxy fotomontażu (BE).";
            case ATRIUM_INTERNAL_ERROR:
                return "Wewnętrzny błąd systemu Atrium.";
            case DP_BE_INTERNAL_ERROR :
                return "Wewnętrzny błąd systemu. Spróbuj ponownie.";
            default:
                return defaultMessage;
        }
    },

};

export default ErrorService;

