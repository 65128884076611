import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Messages } from "primereact/messages";
import React from 'react';
import { withRouter } from "react-router";
import { ADMIN_ROLE_KEY, CONSTRAINT_VIOLATION_ERROR_CODE, defaultDetail, defaultSummary } from "../../config/constants";
import AccountService from "../../services/AccountService";
import CurrentAccountService from "../../services/CurrentAccountService";
import GroupService from "../../services/GroupService";
import RoleService from "../../services/RoleService";
import { copyValueToClipboard } from "../../shared/CardCommon";
import { FilteredPickList } from "../../shared/FilteredPickList";
import { JSONUtil } from "../../shared/Utils";
import ValidationService from "../../shared/ValidationService";
import '../../static/css/App.css';
import ChangeAnotherAccountPasswordDialog from "../auth/ChangeAnotherAccountPasswordDialog";
import { groupTemplate } from "./groupTemplate";
class Account extends React.Component {


    constructor(props) {
        super(props);
        this.onGroupChange = this.onGroupChange.bind(this);
        this.state = {
            source: [],
            target: [],
            error: {
            },
            allRoles: [],
            roles: [],
            login: "",
            password1: "",
            password2: "",
            email: "",
            firstName: "",
            lastName: "",
            isAdmin: false,
            editMode: false,
        };
    }

    componentDidMount() {
        const editMode = !!this.props.match.params.id;
        this.setState({ editMode: editMode });

        GroupService.getAll(group => {
            group.sort((a, b) => {
                if (a.verboseName.toLowerCase() < b.verboseName.toLowerCase()) {
                    return -1;
                }
                if (a.verboseName.toLowerCase() > b.verboseName.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
            this.setState({ source: group });
        });

        if (editMode) {
            this.fetchAccount();
        }


        RoleService.getAll((data) => {
            this.setState({ allRoles: data });

        }, (error) => {
            console.log(`Error=${JSON.stringify(error)}`);
        });

    }

    fetchAccount() {
        AccountService.getById(this.props.match.params.id, (data) => {

            // Removing from source user groups
            const uG = data.groups;
            const source = this.state.source;
            if (uG && this.state.source) {
                for (let i = 0; i < uG.length; i += 1) {
                    const uge = uG[i];
                    const index = source.findIndex(g => g.id === uge.id);
                    if (index >= 0) {
                        source.splice(index, 1);
                    }
                }
            }


            this.setState({
                id: data.id,
                isActualUser: data.id === CurrentAccountService.getId(),
                isAdmin: CurrentAccountService.hasRoleInArray(ADMIN_ROLE_KEY, CurrentAccountService.getRoles()),
                login: data.login,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                roles: data.roles,
                enabled: data.enabled,
                installer: data.installer ? data.installer : {},
                error: { installer: {} },
                target: uG,
                source: source

            }, () => {

            });
        }, () => {
        });
    }

    handleOnChange = (e) => {
        const value = e.target.value;
        const resp = JSONUtil.getNestedJsonObject(this.state, value, e.target.id);
        this.setState(
            resp
        );
    }

    showError = (summary, detail) => {
        this.messages.show({ severity: 'error', summary: summary, detail: detail });
    }

    add = () => {
        const isValid = this.validate();

        if (isValid) {
            var user = {
                login: this.state.login,
                password: this.state.password1,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                roles: this.state.roles.map(e => {
                    return { "id": e.id };
                }),
                groups: this.state.target.map(e => {
                    return { "id": e.id };
                }),

            };

            AccountService.add(user, () => {
                this.props.history.push("/accounts");
            }, (error) => {

                let detail = "Operacja utworzenia nowego użytkownika zakończyła się niepowodzeniem";
                console.log(`error ${error}`);
                if (error && error.errorCode) {
                    if (error.errorCode === CONSTRAINT_VIOLATION_ERROR_CODE) {
                        detail = "Użytkownik o podanym loginie lub e-mail już istnieje.!";
                    }
                }
                this.showError(defaultSummary,
                    detail);
            });
        }

    }

    edit = () => {

        const isValid = this.validate();

        if (isValid) {
            const user = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                groups: this.state.target.map(e => {
                    return { "id": e.id };
                }),
            };


            AccountService.edit(this.state.id, user, () => {
                this.setState({ error: { installer: {} } });
                this.props.history.push("/accounts");
            }, (error) => {

                let detailL = defaultDetail;
                console.log(`error ${error}`);
                if (error && error.errorCode) {
                    if (error.errorCode === CONSTRAINT_VIOLATION_ERROR_CODE) {
                        detailL = "Użytkownik o podanym loginie lub e-mail już istnieje.!";
                    }
                }
                this.showError(defaultSummary,
                    detailL);
            });
        }

    }

    blockUnblock = () => {


        AccountService.blockUnblock(this.state.id, !this.state.enabled, () => {
            this.fetchAccount();
        }, (error) => {
            console.log(`error ${error}`);
            this.showError(defaultSummary,
                defaultDetail);
        });


    }

    delete = () => {


        AccountService.delete(this.state.id, () => {
            this.setState({ installer: {}, error: { installer: {} } });
            this.props.history.push("/accounts");
        }, (error) => {

            console.log(`error ${error}`);
            this.showError(defaultSummary,
                defaultDetail);
        });


    }

    validate() {

        const validationService = new ValidationService();

        if (!this.state.editMode) {

            validationService.notEmpty("login", this.state.login);
            validationService.validPassword("password1", this.state.password1);
            validationService.validPassword("password2", this.state.password2);
            validationService.twoFieldsEqual("password1", this.state.password1, this.state.password2);
            validationService.twoFieldsEqual("password2", this.state.password1, this.state.password2);
        }

        validationService.lengthGreaterThan(2, "firstName", this.state.firstName);
        validationService.lengthGreaterThan(2, "lastName", this.state.lastName);
        if (this.state.email) {
            validationService.validEmail("email", this.state.email);
        }



        const errorObj = validationService.validate();

        const errorObj2 = errorObj.isValid ? { "error": { } } : { error: errorObj["error"] };
        if (!errorObj2["error"]["installer"]) {
            errorObj2["error"]["installer"] = {};
        }

        this.setState(errorObj2);
        return errorObj.isValid;
    }

    onGroupChange(event) {


        this.setState({
            source: event.source,
            target: event.target
        });

    }

 

    showChangeAnotherAccountPasswordDialog() {
        return () => this.setState({ changeAnotherAccountPasswordDialogVisible: true });
    }

    render() {
        return (
            <div className="p-grid nogutter  p-component  ">

                <Messages ref={(el) => this.messages = el} />
                <div className="p-grid nogutter  p-component p-col-12 " style={{ "marginTop": "5px" }}>

                    <div className="p-grid p-component p-col-6">
                        <div className="p-grid nogutter p-col-12 ">
                            <label className=" p-col-2 label" htmlFor="in">Login:</label>
                            <InputText placeholder="" id="login" type="text" required={true}

                                disabled={this.state.editMode || this.state.isInstaller}
                                value={this.state.login}
                                onChange={this.handleOnChange} />
                            <button style={{ margin:"0.10em 0.10em 0.10em 0.15em", minWidth:"1.5em" }} className="p-button pi pi-copy" onClick={() => {
                                copyValueToClipboard(this.state.login);
                            }
                            }></button>
                            {
                                this.state.error.login &&
                                <div className="validation-msg"><Message severity="error"
                                    text={this.state.error.login} />
                                </div>
                            }
                        </div>

                        {this.state.editMode && this.state.isAdmin && !this.state.isActualUser &&
                            <div className="p-grid nogutter p-col-12">
                                <div className="p-col-6"
                                    style={{ "marginLeft": "17.5%" }}>  {/*skorygowany odpowiednik p-offset-2, ktory daje 16.777%*/}
                                    <Button label="Zmień hasło" onClick={this.showChangeAnotherAccountPasswordDialog()} />
                                </div>
                                <ChangeAnotherAccountPasswordDialog visible={this.state.changeAnotherAccountPasswordDialogVisible} onHide={() => this.setState({ changeAnotherAccountPasswordDialogVisible: false })}
                                    userName={this.state.login} accountId={this.state.id}
                                    onSuccess={this.showSuccessDialog} />
                            </div>
                        }


                        {!this.state.editMode && <div className="p-grid nogutter p-col-12 ">
                            <label className="p-col-2 label" htmlFor="in">Hasło:</label>
                            <InputText id="password1" type="password" value={this.state.password1}
                                onChange={this.handleOnChange} required={true} />
                            {this.state.error.password1 &&
                                <div className="validation-msg"><Message severity="error"
                                    text={this.state.error.password1} />
                                </div>}
                        </div>
                        }

                        {!this.state.editMode && <div className="p-grid nogutter p-col-12 ">
                            <label className="p-col-2 label" htmlFor="in">Hasło:</label>
                            <InputText id="password2" type="password" value={this.state.password2}
                                onChange={this.handleOnChange} required={true} />
                            {this.state.error.password2 &&
                                <div className="validation-msg"><Message severity="error"
                                    text={this.state.error.password2} />
                                </div>}
                        </div>
                        }

                        <div className="p-grid nogutter p-col-12 ">
                            <label className="p-col-2 label" htmlFor="in">Imię:</label>
                            <InputText placeholder="" id="firstName" type="text" className="validate p-component"
                                required={true} value={this.state.firstName}
                                onChange={this.handleOnChange} />
                            {this.state.error.firstName &&
                                <div className="validation-msg"><Message severity="error"
                                    text={this.state.error.firstName} />
                                </div>}
                        </div>


                        {this.state.editMode && <div className="p-grid nogutter p-col-12 ">
                            <label className="p-col-2 label" htmlFor="in">Zablokowany:</label>
                            <Checkbox checked={!this.state.enabled}></Checkbox>

                        </div>
                        }

                        <div className="p-grid nogutter p-col-12 ">
                            <label className="p-col-2 label" htmlFor="in">Nazwisko:</label>
                            <InputText placeholder="" id="lastName" type="text" className="validate p-component"
                                required={true}
                                onChange={this.handleOnChange} value={this.state.lastName} />
                            {this.state.error.lastName &&
                                <div className="validation-msg"><Message severity="error"
                                    text={this.state.error.lastName} />
                                </div>}
                        </div>

                        <div className="p-grid nogutter p-col-12 ">
                            <label className="p-col-2 label" htmlFor="in">Email:</label>
                            <InputText placeholder="" id="email" type="email" className=" o-value" required={true}
                                onChange={this.handleOnChange} value={this.state.email} />
                            {this.state.error.email &&
                                <div className="validation-msg"><Message severity="error"
                                    text={this.state.error.email} /></div>}
                        </div>
                    </div>
                    <div className="p-grid p-component p-col-10" style={{ marginLeft: '1em' }}>
                        <FilteredPickList source={this.state.source} target={this.state.target}
                            itemTemplate={groupTemplate}
                            sourceHeader="Dostępne" targetHeader="Wybrane" responsive={true}
                            sourceStyle={{ height: '300px' }} targetStyle={{ height: '300px' }}
                            onChange={this.onGroupChange} searchBy={"verboseName"}
                            showSourceControls={false} showTargetControls={false} />
                    </div>
                </div>

                <div className="p-grid p-component " style={{ marginLeft: '1em' }}>
                    {this.state.editMode &&
                        <button type="submit" className="button btn waves-effect waves-light"
                            onClick={() => this.delete()}
                            name="action">{"Usuń"}
                        </button>
                    }
                    {this.state.editMode &&
                        <button type="submit" className="button btn waves-effect waves-light"
                            onClick={() => this.blockUnblock()}
                            name="action">{this.state.enabled ? "Zablokuj" : "Odblokuj"}
                        </button>
                    }
                    <button type="submit" className="button btn waves-effect waves-light"
                        onClick={() => this.state.editMode ? this.edit() : this.add()}
                        disabled={this.state.editMode && !this.state.enabled}
                        name="action">Zapisz
                    </button>
                </div>
            </div>
        );

    }
}

export default withRouter(Account);


