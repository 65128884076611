import '../../static/css/App.css';
import React from 'react';

import Order from "../other/Order";
import { withRouter } from 'react-router-dom';
import OrderPreviewService from "../../services/OrderPreviewService";
import {lastCreationDate, setHistoryMetadata} from "../../shared/Utils";

class OrderPreview extends React.Component {


    //https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component/
    _isMounted = false;

    state = {
        order: {
            area: [],
            installers: [],
            technology: {},
            technologyDla: {},
            service: {},
            status: {},
            type: {},
            rejectionReason: {},
            orderDevices: [],
            chatHistory: {}
        },
        events: []

    }

    componentDidMount() {
        this._isMounted = true;

        this.getOrder();

    }

    getOrder() {
        OrderPreviewService.getById(this.props.match.params.id,
            (data) => {
                if (this._isMounted) {
                    setHistoryMetadata(data);
                    this.setState({order: data});
                }
            },
            (error) => {
                console.log(`Error: ${error}`);
            });
    }

    getEvents(id) {
        OrderPreviewService.getEventsByIdWithTimestamp(id, lastCreationDate(this.state.events),
            (data) => {
                if (data) this.setState({events: data})
            },
            (error) => {
                console.log("Error: " + error)
            })
    }

    getChatHistory(id) {
        OrderPreviewService.getChatHistoryById(id,
            (data) => {
                if (data) this.setState({chatHistory: data})
            },
            (error) => {
                console.log("Error: " + error)
            })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {

        //https://reacttraining.com/react-router/web/example/url-params
        const {match} = this.props;

        return (
            <div style={{"paddingTop": "7px"}}>
                <Order dataSource={this.state.order} match={match} refreshOrder={()=> {this.getOrder()}} refreshEvents={() => this.getEvents(this.state.order.id)} events={this.state.events}
                       monitoringPreviewOrBots={true} refreshChatHistory={() => this.getChatHistory(this.state.order.id)} chatHistory={this.state.chatHistory}/>
            </div>
        );

    }
}
export default withRouter(OrderPreview);
