import '../../static/css/App.css';
import React from 'react';

import Order from "../other/Order";
import OrderService from "../../services/OrderService";
import {withRouter} from 'react-router-dom';
import {setHistoryMetadata} from "../../shared/Utils";
import OrderPreviewService from "../../services/OrderPreviewService";

class HistoryOrder extends React.Component {


    //https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component/
    _isMounted = false;

    state = {
        order: {
            area: [],
            installers: [],
            technology: {},
            technologyDla: {},
            service: {},
            status: {},
            type: {},
            rejectionReason: {},
            orderDevices: [],
        }

    }

    componentDidMount() {
        this._isMounted = true;

        this.getOrder();

    }

    getOrder() {
        OrderService.getById(this.props.match.params.id,
            (data) => {
                setHistoryMetadata(data);
                if (this._isMounted) {
                    this.setState({order: data});
                }
            },
            (error) => {
                console.log(`Error: ${error}`);
            });
    }

    getChatHistory(id) {
        OrderPreviewService.getChatHistoryById(id,
            (data) => {
                if (data) this.setState({chatHistory: data})
            },
            (error) => {
                console.log("Error: " + error)
            })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {

        //https://reacttraining.com/react-router/web/example/url-params
        const {match} = this.props;

        return (
            <Order dataSource={this.state.order} match={match} refreshOrder={()=>{
                this.getOrder();
            }} refreshChatHistory={() => this.getChatHistory(this.state.order.id)} chatHistory={this.state.chatHistory}/>

        );

    }
}

export default withRouter(HistoryOrder);
