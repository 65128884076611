import React from "react";
import '../static/css/App.css';
import DealerSupportRagResponseDropdown from "./DealerSupportRagResponseDropdown";
import {InputText} from "primereact/inputtext";

class DealerSupportRagResponseDiv extends React.Component {
    render() {
        return (<div className="p-grid nogutter p-col-12">
                <label className="p-col-3 o-label" htmlFor="in">Wybierz wynik:</label>
                <span className="o-value">
                        <DealerSupportRagResponseDropdown className={"width250"} onChange={(event) => {
                            this.setState({ragResponse: event.value});
                            this.props.onChange(event);
                        }}/>
                    </span>
            </div>
        );
    }
}

export default DealerSupportRagResponseDiv;