//https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript
import _ from 'lodash';

const ActivationHistoryMetadataCreator = {

    generateMetamodel(order, activationHistoryList) {

        let historyActivation = {orderDevices: []}
        let activation = {orderDevices: []}


        let metaData = {
            activation: activation,
            historyActivation: historyActivation
        };


        if (!_.isNil(order) && !_.isEmpty(activationHistoryList)) {

            let activationHistory = activationHistoryList[0];
            let orderDevices = order.orderDevices;
            let historyOrderDevices = activationHistory.orderDevices;
            _.forEach(historyOrderDevices, function (value, key) {
                let hOrderDevice = value;
                _.forEach(orderDevices, function (value, key) {
                    var result = _.isEqual(
                        _.omit(value, ['device.id']),
                        _.omit(hOrderDevice, ['device.id', 'device.status'])
                    );
                    if (result) {
                        historyActivation.orderDevices.push({device: {id: hOrderDevice.device.id, equal: true}});
                        activation.orderDevices.push({device: {id: value.device.id, equal: true}});
                    }

                });
            });
        }

        return metaData;
    }


};

export default ActivationHistoryMetadataCreator;

