import RestService from "./RestService";

const PATH = "/monitoring/order/";

const MonitoringService = {

    getById(id, onSuccess, onError) {
        return RestService.get(PATH + id, onSuccess, onError);
    },

    assingOplUser(id, onSuccess, onError) {
        RestService.put(`${PATH + id}/opl/assign`, onSuccess, onError);
    },

    assingOplUserLate(id, onSuccess, onError) {
        RestService.put(`${PATH + id}/opl/assignLate`, onSuccess, onError);
    },

    unassingOplUser(id, onSuccess, onError) {
        RestService.put(`${PATH + id}/opl/unassign`, onSuccess, onError);
    },

    pullFromQueueAndAssign(onSuccess, onError) {
        RestService.getWithParams(`${PATH}/assign`, {}, onSuccess, onError);
    },

    getByIdWithTimestamp(id, modificationDateTime, onSuccess, onError) {
        const params = { 'timestamp': modificationDateTime };
        return RestService.getWithParams(`/monitoring/order/${id}`, params, onSuccess, onError);
    },

    getDelays(id, onSuccess, onError) {
        return RestService.get(`${PATH + id}/delay`, onSuccess, onError);
    },

    resumeOrderAndForwardToInstaller(orderId, data, onSuccess, onError) {
        RestService.update(`${PATH + orderId}/resume`, data, onSuccess, onError);
    },

    resumeOrderAndForwardToInstallerLate(orderId, data, onSuccess, onError) {
        RestService.update(`${PATH + orderId}/resumeLate`, data, onSuccess, onError);
    },

    discard(orderId, data, onSuccess, onError) {
        RestService.update(`${PATH + orderId}/discard`, data, onSuccess, onError);
    },

    changeToInProgress(orderId, onSuccess, onError) {
        RestService.put(`${PATH + orderId}/inProgress`, onSuccess, onError);
    },

    getAll(params, pageNumber, pageSize, onSuccess, onError) {
        RestService.getWithPagination(`${PATH}all`, params, pageNumber, pageSize, onSuccess, onError);
    },

    getToday(params, pageNumber, pageSize, onSuccess, onError) {
        RestService.getWithPagination(`${PATH}today`, params, pageNumber, pageSize, onSuccess, onError);
    },

    getHistory(params, pageNumber, pageSize, onSuccess, onError) {
        RestService.getWithPagination(`${PATH}history`, params, pageNumber, pageSize, onSuccess, onError);
    },

    getToTake(params, pageNumber, pageSize, onSuccess, onError) {
        RestService.getWithPagination(`${PATH}toTake`, params, pageNumber, pageSize, onSuccess, onError);
    },

    getLate(params, pageNumber, pageSize, onSuccess, onError) {
        RestService.getWithPagination(`${PATH}late`, params, pageNumber, pageSize, onSuccess, onError);
    },

    getOrdersWithQuestionToContractStatus(params, pageNumber, pageSize, onSuccess, onError) {
        RestService.getWithPagination(`${PATH}questionToContract`, params, pageNumber, pageSize, onSuccess, onError);
    },

    getEventsByIdWithTimestamp(id, modificationDateTime, onSuccess, onError) {
        let params = { 'timestamp': modificationDateTime };
        return RestService.getWithParams(PATH + id + "/events", params, onSuccess, onError);
    },

};

export default MonitoringService;

